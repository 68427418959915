@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");
@import "./mixin.scss";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
}

body {
  font-size: 15px;
  line-height: normal;
  color: #000;
  font-family: "Roboto", sans-serif;
  background: #f5f6fa;

  &::-webkit-scrollbar {
    width: 3px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #e5e5e5;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
}

a {
  outline: none;
  text-decoration: none;
  color: #000;
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  -ms-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}

a:hover {
  text-decoration: none;
}

input,
textarea,
select {
  font-size: 14px;
  color: #000;
  vertical-align: middle;
  font-family: "Roboto", sans-serif;
  outline: none;
  resize: none;
}

select {
  background: url("../../../public/images/dropdown_arrow.svg") no-repeat center;
  background-position: 94% center;
}

input[type="button"],
input[type="submit"],
button {
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  -ms-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

select {
  appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

.transition {
  -webkit-transition: ease-in-out 0.5s;
  -moz-transition: ease-in-out 0.5s;
  -ms-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
}

img {
  border: none;
  vertical-align: top;
  max-width: 100%;
}

.container {
  width: 100%;
  margin: auto;
  max-width: 1600px;
  padding: 0 15px;
}

.flexBox {
  display: flex;
  flex-wrap: wrap;
}

.flexBox.nowrap {
  flex-wrap: nowrap;
}

.itemCenter {
  align-items: center;
}

.justifyContentBetween {
  justify-content: space-between;
}

.justifyContentCenter {
  justify-content: center;
}

.tac {
  text-align: center !important;
}

.container {
  width: 100%;
  margin: auto;
  max-width: 1600px;
  padding: 0 15px;
}

.pr {
  position: relative;
}

.loginLeft {
  width: 50%;
  height: 100vh;
  background-size: cover !important;

  @include respond-to(media-sm) {
    width: 100%;
  }
}

.loginBg {
  background-size: cover !important;
  width: 50%;
  height: 100vh;

  @include respond-to(media-sm) {
    display: none;
  }
}

.loginTop {
  height: 100vh;
  padding: 15px;
  overflow: auto;
}

.logininner {
  width: 500px;
  margin: auto;
}

.loginLogo {
  margin-bottom: 20px;
}

.loginHd {
  font-size: 30px;
  margin-bottom: 15px;
  font-weight: 700;
}

.loginText {
  color: #475467;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}

.loginForm {
  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.formInput {
  border: 1px solid #dbdbdb;
  width: 100%;
  height: 54px;
  border-radius: 8px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 500;

  @include respond-to(media-sm) {
    padding: 0 10px;
    height: 48px;
    border-radius: 4px;
    font-size: 15px;
  }

  &:textarea {
    @include respond-to(media-sm) {
      padding: 10px;
    }
  }
}

.rem {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
}

a.fpLink {
  color: #1a73e9;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}

.loginBtn {
  width: 100%;
  background: $primary;
  border-radius: 8px;
  height: 54px;
  font-size: 18px;
  border: none;
  font-weight: 500;
  color: #fff;
  margin-top: 10px;

  @include respond-to(media-sm) {
    border-radius: 4px;
    font-size: 16px;
    height: 48px;
    margin-top: 5px;
  }

  &:hover {
    background: #000;
  }
}

.checkWrapper {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 4px 0;
}

.checkInpt {
  opacity: 0;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  margin: 0;
}

.checkInpt+.bg {
  height: 24px;
  width: 24px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #dbdbdb;
  background: #fff;
  border-radius: 4px;
}

.checkInpt:checked+.bg {
  background: $primary url(../../../public/images/check_icon.png) no-repeat center;
  border: 1px solid $primary;
}

.eye {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
  color: #98a2b3;
}

.erroeMessage {
  background: #ffe2df;
  width: 100%;
  border: 1px solid #f48e8f;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  font-size: 15px;
  margin-top: 10px;
  font-weight: 500;
}

.checkbox {
  .form-check-input {
    width: 24px;
    height: 24px;
    background: #fff;
    border: 1px solid #bdbdbd;
    border-radius: 4px;

    &:checked {
      background: $primary url(../../../public/images/check_icon.png) no-repeat center;
      border-color: $primary;
      color: #fff;
    }
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  overflow: auto;
  display: none;

  &.active {
    display: block;
  }
}

.popupInner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 15px;
}

.popupBox {
  background: #fff;
  max-width: 550px;
  width: 100%;
  margin: auto;
  border-radius: 12px;
  position: relative;
  padding: 40px;

  @include respond-to(media-sm) {
    padding: 20px;
  }
}

.closePopup {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #000;

  @include respond-to(media-sm) {
    right: 5px;
    top: 5px;
  }

  span {
    font-size: 32px;

    @include respond-to(media-sm) {
      font-size: 24px;
    }
  }

  &:hover {
    color: $primary;
  }
}

.popupHd {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 700;
}

.sucessfull {
  margin-bottom: 20px;

  span {
    font-size: 120px;
    color: $primary;
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-dialog {
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 100%;

  .modal-content {
    background: none;
    border: none;
  }
}

.dbCon.active .dbLeft {
  left: -260px;

  @include respond-to(media-lg) {
    left: 0;
    height: calc(100% - 70px);
  }
}

.dbCon.active .dbHeader {
  width: 100%;
}

.dbCon.active .dbRight {
  padding: 90px 20px 20px 20px;

  @include respond-to(media-sm) {
    padding: 85px 15px 15px 15px;
  }
}

.dbHeader {
  width: calc(100% - 260px);
  height: 70px;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1001;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: ease-in-out 0.5s;

  @include respond-to(media-lg) {
    width: 100%;
  }

  @include respond-to(media-sm) {
    padding: 0 15px;
  }
}

.menuToggle {
  cursor: pointer;
}

a.showSearch {
  display: none;

  @include respond-to(media-sm) {
    display: block;
    margin-left: 15px;
  }
}

.topSearch {
  position: relative;
  margin-left: 20px;

  @include respond-to(media-sm) {
    position: fixed;
    bottom: -100px;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 15px;
    border-top: 1px solid #ccc;
    margin: 0;
    transition: ease-in-out 0.5s;

    &.active {
      bottom: 0;
    }
  }

  .input {
    width: 400px;
    height: 40px;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
    padding: 0 40px 0 15px;

    @include respond-to(media-lg) {
      width: 300px;
    }

    @include respond-to(media-sm) {
      width: 100%;
    }
  }

  .btn {
    position: absolute;
    right: 0;
    top: 0;
    color: #666;
    width: 40px;
    height: 100%;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-to(media-sm) {
      right: 15px;
    }
  }
}

.date {
  border: 1px solid #d5d5d5;
  height: 40px;
  background: #d9f7e7;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 15px;
  margin-right: 20px;

  @include respond-to(media-xs) {
    display: none;
  }

  span {
    @include respond-to(media-lg) {
      display: none;
    }
  }
}

.notification {
  margin-right: 25px;
  position: relative;
}

.notification a .count {
  width: 16px;
  height: 16px;
  background: #ff0000;
  border-radius: 100%;
  position: absolute;
  right: -5px;
  top: -5px;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.account a {
  color: #404040;
  font-size: 14px;
  font-weight: 500;
}

.account a img {
  margin-right: 8px;
}

.dbLeft {
  width: 260px;
  background: #fff;
  position: fixed;
  border-right: 1px solid #e0e0e0;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  transition: ease-in-out 0.5s;

  @include respond-to(media-lg) {
    left: -260px;
    top: 70px;
  }
}

.dbLogo {
  padding: 20px;
}

.dbLogo img {
  width: 185px;
}

.dbMenu {
  padding: 0 20px;
}

.dbMenu li {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 1px;
}

.dbMenu li a {
  color: #606060 !important;
  height: 48px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.dbMenu li a .icon {
  margin: 0 10px 4px 0;
}

.dbMenu li a:hover,
.dbMenu li a.active {
  background: $primary;
  color: #fff !important;
}

.dbMenu.top {
  height: calc(100vh - 280px);
  padding-bottom: 20px;

  @include respond-to(media-lg) {
    height: calc(100vh - 340px);
  }
}

.dbMenu.bot {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
}

.dbRight {
  padding: 90px 20px 20px 280px;
  transition: ease-in-out 0.5s;

  @include respond-to(media-lg) {
    padding: 90px 20px 20px 20px;
  }

  @include respond-to(media-sm) {
    padding: 85px 15px 15px 15px;
  }
}

.mainHd {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 15px;

  @include respond-to(media-sm) {
    font-size: 20px;
  }
}

.statsList {
  margin: -10px;
}

.statsList li {
  padding: 10px;
  width: 20%;

  @include respond-to(media-xl) {
    width: 33.33%;
  }

  @include respond-to(media-sm) {
    width: 50%;
  }

  @include respond-to(media-xs) {
    width: 100%;
  }
}

.statsList li .inner {
  background: #fff;
  border-radius: 12px;
  padding: 15px;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.statsList li .inner .top {
  align-items: flex-start;
}

.statsList li .inner .top .hd {
  color: #606060;
  font-size: 16px;
  font-weight: 500;
  padding-right: 10px;
  width: calc(100% - 60px);
}

.statsList li .inner .top .hd span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  padding-top: 10px;
  color: #000;
}

.statsList li .inner .top .icon {
  width: 60px;
  height: 60px;
  border-radius: 22px;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statsList li .inner .bot {
  margin-top: 20px;
  color: #606060;
  font-size: 15px;
}

.statsList li .inner .bot img {
  margin-right: 10px;
}

.statsList li .inner .bot .number {
  margin-right: 5px;
}

.statsList li .inner .bot .number.green {
  color: #00b69b;
}

.statsList li .inner .bot .number.red {
  color: #ff0000;
}

.mt20 {
  margin-top: 20px;
}

.whtBox {
  border: 1px solid #dfdfdf;
  border-radius: 14px;
  background: #fff;
  padding: 20px;

  @include respond-to(media-sm) {
    padding: 10px;
  }
}

.dbSubHd {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

.filter {
  width: 60%;
  max-width: 750px;
  margin: -5px;
  padding: 0 0 15px 0;

  @include respond-to(media-md) {
    width: calc(100% + 10px);
    max-width: calc(100% + 10px);
  }
}

.filter.end {
  justify-content: flex-end;
}

.filter li {
  padding: 5px;
  width: 25%;

  @include respond-to(media-xs) {
    width: 50%;
  }
}

.filter li:first-child {
  width: 50%;

  @include respond-to(media-xs) {
    width: 100%;
  }
}

.filterInput {
  width: 100%;
  border: 1px solid #cccccc;
  height: 34px;
  background: #f1f4f9;
  border-radius: 4px;
  padding: 0 20px 0 12px;
}

.filterSelect {
  width: 100%;
  border: 1px solid #cccccc;
  height: 34px;
  background: #f1f4f9 url(../../../public/images/select-arrow.png) no-repeat right 5px center;
  border-radius: 4px;
  padding: 0 12px;
}

.filterDate .icon {
  position: absolute;
  right: 5px;
  top: 5px;
}

.filterDate .filterInput {
  padding-right: 25px;
}

.filterSearch .icon {
  position: absolute;
  right: 5px;
  top: 5px;
}

.filterSearch .filterInput {
  padding-right: 25px;
}

.dbTable {
  width: 100%;
  overflow: auto;
}

.dbTable table {
  width: 100%;
  min-width: 767px;
  border-spacing: 0;
}

.dbTable table th {
  background: #f1f4f9;
  padding: 15px 12px;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
}

.dbTable table tr th:first-child {
  border-radius: 14px 0 0 14px;
}

.dbTable table tr th:last-child {
  border-radius: 0 14px 14px 0;
}

.dbTable table td {
  background: #fff;
  padding: 15px 12px;
  font-size: 15px;
  border-bottom: 1px solid #e7e7e7;
}

.dbTable table tr:last-child td {
  border: none;
  padding-bottom: 0;
}

.status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 28px;
  border-radius: 6px;
  padding: 0 15px;
  min-width: 85px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.status.success {
  background: #00b69b;
}

.status.failed {
  background: #fd5454;
}

.status.pending {
  background: #fcbe2d;
}

a.viewLink {
  display: inline-flex;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: 1px solid #cfcfcf;
  height: 28px;
  padding: 0 15px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 6px;
}

a.viewLink:hover {
  background: #00b69b;
  border-color: #00b69b;
  color: #fff;
}

.total {
  color: #606060;
  padding-top: 20px;
  font-size: 14px;
}

.paging {
  padding: 20px 0 0 0;
  display: flex;
}

.paging a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-left: 10px;
}

.paging a:hover {
  background: #00b69b;
  border-color: #00b69b;
  color: #fff;
}

.receiptBox {
  width: 550px;
  height: 100%;
  background: #fff;
  position: fixed;
  right: 0;
  top: 0;
  overflow: auto;
  padding: 20px;

  @include respond-to(media-sm) {
    width: 100%;
    padding: 15px;
  }
}

.paymentDetList>li {
  border-bottom: 1px solid #e7e7e7;
  padding: 20px 0;
  color: #000;

  @include respond-to(media-sm) {
    padding: 10px 0;
  }
}

.paymentDetList>li.borNone {
  border: none;
}

.paymentDetList>li .amount {
  font-size: 16px;
}

.paymentDetList>li .amount span {
  display: block;
  font-size: 32px;
  font-weight: 700;
  padding-top: 5px;
}

.paymentDetList>li .paidTo {
  font-size: 14px;
  line-height: 24px;
}

.paymentDetList>li .paidTo span {
  display: block;
  font-weight: 500;
}

.paymentDetList>li .paidTo strong {
  display: block;
  font-weight: 700;
  font-size: 17px;
}

.paymentDetList>li .transaction {
  font-size: 14px;
  line-height: 24px;
  padding-top: 10px;
}

.paymentDetList>li .transaction span {
  color: #8a8a8d;
  display: block;
}

.timelineHd {
  display: block;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 15px;
}

.timeline {
  position: relative;
}

.timeline:before {
  content: "";
  background: #dcdcdc;
  position: absolute;
  left: 10px;
  top: 5%;
  height: 90%;
  width: 1px;
}

.timeline li {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  padding-left: 40px;
}

.timeline li:last-child {
  margin-bottom: 0;
}

.timeline li:before {
  content: "done";
  background: $primary;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 10px;
  font-family: "Material Icons";
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline li span {
  display: block;
  color: #8a8a8d;
  font-weight: 400;
  padding-top: 5px;
}

.downloadBtns a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  border: 1px solid #1a73e9;
  border-radius: 8px;
  height: 40px;
  color: #1a73e9;
  font-size: 15px;
  font-weight: 500;
}

.downloadBtns a:hover {
  background: #1a73e9;
  color: #fff;
}

.downloadBtns a.downloadReceipt {
  color: #00b69b;
  border-color: #00b69b;
}

.downloadBtns a.downloadReceipt:hover {
  background: #00b69b;
  color: #fff;
}

.totalNumber {
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 15px;
}

.mr10 {
  margin-right: 10px;
}

.nowrap {
  white-space: nowrap;
}

a.viewLink.unblock {
  color: #fd5454;
  border-color: #fd5454;
  background: #fdeaea;
}

a.viewLink.unblock:hover {
  background: #fd5454;
  color: #fff;
}

.roundImg {
  border-radius: 100%;
}

.loginBtn.block {
  background: #fd5454;
}

.loginBtn.block:hover {
  background: #000;
}

.userName {
  font-size: 18px;
  font-weight: 500;
}

.userName img {
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin-right: 15px;
}

.userInfo {
  border-top: 1px solid #dcdcdc;
  margin: 20px 0 0 0;
  padding: 25px 0 0 0;
}

.userInfo li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 0 0 25px 0;
}

.userInfo li .right {
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
  text-align: right;
}

.userInfo li.total {
  border-top: 1px solid #dcdcdc;
  padding-top: 25px;
}

.filter.transactionsFilter {
  width: 70%;
  max-width: 850px;

  @include respond-to(media-md) {
    width: calc(100% + 10px);
    max-width: calc(100% + 10px);
  }
}

.filter.transactionsFilter li {
  width: 20%;

  @include respond-to(media-xs) {
    width: 33.33%;
  }
}

.filter.transactionsFilter li:first-child {
  width: 40%;

  @include respond-to(media-xs) {
    width: 100%;
  }
}

.newKeyBox {
  border-radius: 10px;
  padding: 25px;
  position: relative;
  overflow: hidden;
  background: url(../../../public/images/key-bg.png) no-repeat right top;
  background-size: cover;

  @include respond-to(media-xs) {
    padding: 15px;
  }
}

.newKeyBox .hd {
  display: block;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 5px;
}

.keyBox {
  width: 100%;
  max-width: 900px;
  padding-right: 170px;
  position: relative;

  @include respond-to(media-xs) {
    padding: 0;
  }
}

.keyBox .input {
  width: 100%;
  background: #fff;
  height: 54px;
  border-radius: 8px;
  font-size: 16px;
  padding: 0 15px;
  border: none;

  @include respond-to(media-xs) {
    height: 48px;
  }
}

.keyBox .btn {
  width: 160px;
  background: $primary;
  height: 54px;
  border-radius: 8px;
  font-size: 18px;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 15px;
  border: none;

  @include respond-to(media-xs) {
    position: static;
    width: 100%;
    margin-top: 10px;
    height: 48px;
  }
}

.keyBox .btn:hover {
  background: #000;
}

.p0 {
  padding: 0 !important;
}

.nav-tabs {
  border-bottom: 2px solid #e0e4ec;
}

.nav-tabs li button {
  display: flex;
  height: 50px;
  background: none !important;
  border: none !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #717b8c;
  font-size: 16px;
  font-weight: 500;
  min-width: 200px;
  position: relative;

  @include respond-to(media-sm) {
    min-width: inherit;
    padding: 0 15px;
  }
}

.nav-tabs li button:hover {
  color: #000;
}

.nav-tabs li button.active {
  color: #000;
}

.nav-tabs li button.active:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #000;
  position: absolute;
  left: 0;
  bottom: -1px;
}

.settingFormBox {
  padding: 25px;
  align-items: flex-start;

  @include respond-to(media-sm) {
    padding: 15px;
  }
}

.settingImg {
  width: 150px;
  position: relative;

  @include respond-to(media-sm) {
    margin: auto;
  }
}

.settingImg img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
}

.addIcon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cccccc;
  border-radius: 100%;
  position: absolute;
  right: 5px;
  bottom: 5px;
  background: #fff;
  overflow: hidden;
  cursor: pointer;
}

.addIcon .file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.addIcon span {
  font-size: 18px;
}

.settingDet {
  width: calc(100% - 150px);
  padding-left: 40px;
  max-width: 820px;

  @include respond-to(media-sm) {
    width: 100%;
    padding: 25px 0 0 0;
  }
}

.settingDet ul {
  margin: -10px;
}

.settingDet ul li {
  width: 50%;
  padding: 10px;

  @include respond-to(media-sm) {
    width: 100%;
  }
}

.settingDet ul li.full {
  width: 100%;
}

.fieldHd {
  display: block;
  padding-bottom: 5px;
  color: #667085;
  font-size: 14px;
  font-weight: 500;
}

textarea.formInput {
  height: 160px;
  padding: 15px 20px;
  line-height: 24px;

  @include respond-to(media-sm) {
    padding: 10px;
  }
}

.passwordSetting {
  max-width: 600px;
  width: 100%;
  padding: 25px;

  @include respond-to(media-sm) {
    padding: 15px;
  }
}

.passwordSetting ul {
  margin: -10px;
}

.passwordSetting ul li {
  width: 100%;
  padding: 10px;
}

.helpSupportBox {
  align-items: flex-start;
}

.helpSupportBox .left {
  width: calc(100% - 340px);
  padding-right: 30px;

  @include respond-to(media-lg) {
    width: 100%;
    padding: 0 0 20px 0;
  }
}

.supportBox {
  border: 1px solid #dfdfdf;
  border-radius: 12px;
  padding: 25px;
  background: #fff;

  @include respond-to(media-sm) {
    padding: 15px;
  }
}

.supportLinks {
  margin: -10px;
  padding: 0;

  @include respond-to(media-sm) {
    margin: -5px;
  }
}

.supportLinks li {
  padding: 10px;
  width: 50%;

  @include respond-to(media-sm) {
    padding: 5px;
  }

  @include respond-to(media-xs) {
    width: 100%;
  }
}

.supportLinks li a {
  display: block;
  height: 100%;
  background: #f1f4f9;
  border-radius: 14px;
  padding: 15px 40px 15px 15px;
  position: relative;
  font-size: 16px;
  font-weight: 500;

  @include respond-to(media-sm) {
    font-size: 15px;
  }
}

.supportLinks li a:after {
  content: "chevron_right";
  font-family: "Material Icons";
  font-size: 24px;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -12px;
}

.supportLinks li a:hover {
  background: #00b69b;
  color: #fff;
}

.helpSupportBox .right {
  width: 340px;
  text-align: center;
  background: linear-gradient(to right,
      rgba(60, 97, 198, 1) 0%,
      rgba(29, 47, 96, 1) 100%);
  border-radius: 12px;
  padding: 25px;

  @include respond-to(media-lg) {
    width: 100%;
  }

  @include respond-to(media-sm) {
    padding: 15px;
  }
}

.helpSupportBox .right span {
  display: block;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 10px;
}

.helpSupportBox .right a {
  display: block;
  background: $primary;
  border-radius: 8px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 12px;
}

a.backLink {
  background: $primary;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  margin-right: 10px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

a.backLink span {
  font-size: 18px;
}

.qaList>li {
  background: #f1f4f9;
  border-radius: 12px;
  margin-bottom: 15px;
  padding: 20px;

  @include respond-to(media-sm) {
    padding: 12px;
    border-radius: 8px;
  }
}

.list {
  margin: 0 0 0 18px;
  padding: 0;

  li {
    padding: 5px 0 5px 0;
    list-style: auto;
  }
}

.accordion {
  .accordion-item {
    border: none;
    background: #f1f4f9;
    border-radius: 12px;
    margin-bottom: 15px;
    padding: 20px;

    .accordion-button {
      background: none;
      padding: 0;
      font-weight: 600;
      box-shadow: none;
    }

    .accordion-body {
      padding: 15px 0 0 0;
      font-size: 15px;
      line-height: 22px;
    }
  }
}

// Aug 13th css
.filterRow {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.filterRow .totalNumber,
.filterRow .filter {
  padding-bottom: 0;
}

.filterRow .filter {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-end;
}

.bckBtn {
  display: inline-block;
  margin-right: 15px;

  @include respond-to(media-sm) {
    margin-right: 8px;

    img {
      width: 25px;
      height: 25px;
    }
  }
}

.ddBxHd {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 4px;
  text-transform: capitalize;
}

.dtTbl table td {
  vertical-align: top;
}

.dtTbl table td p,
.dtTbl table td p span {
  font-size: 15px;
  line-height: 32px;
  color: #494949;
  margin-bottom: 0;

  @include respond-to(media-sm) {
    line-height: 22px;
    font-size: 14px;
    margin-bottom: 3px;
  }
}

.dtTbl table td p span {
  display: inline-block;
}

.dtTbl table td:first-child {
  width: 125px;
  padding-right: 30px;

  @include respond-to(media-sm) {
    width: 130px;
    padding-right: 15px;
  }
}

.dtTbl table td p span.imgWrp {
  display: block;
}

.dtTbl {
  padding-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 20px;
}

.dsdBtnSec {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.dsdBtnSec .btnn {
  font-size: 18px;
  line-height: 54px;
  width: 153px;
  height: 54px;
  border-radius: 8px;
  color: #f7f7f7;
  text-align: center;
  cursor: pointer;
  outline: none;
  transition: all 0.5s ease-in;
  margin-right: 20px;

  @include respond-to(media-sm) {
    font-size: 15px;
    line-height: 36px;
    width: auto;
    height: 36px;
    padding: 0 16px;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.dsdBtnSec .btnn:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.dsdBtnSec .btnn.refund {
  background: #29d1b8;
}

.dsdBtnSec .btnn.reject {
  background: #f95959;
}

.dsdBtnSec .btnn.more_inf {
  background: #ffffff;
  border: 1px solid #a7a7a7;
  color: #a7a7a7;
}

.kyBox .stps {
  color: #606060;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}

.kyBox .subH {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
}

.KYCformWrp .frmRow {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.KYCformWrp .frmRow>div {
  position: relative;
  width: 24%;
  margin-right: 1.3%;

  &:nth-child(4n) {
    margin-right: 0;
  }

  @include respond-to(media-sm) {
    width: 49%;
    margin-right: 2%;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @include respond-to(media-xs) {
    width: 100%;
    margin-right: 0;
  }
}

.KYCformWrp .frmRow .frmGrp {
  margin-bottom: 20px;
}

span.fieldHd.star {
  display: inline-block;
  padding: 0;
}

.loginBtn.nxtbtn {
  width: 150px;
  padding: 10px 30px;
  height: auto;
}

.btnWrpper {
  margin-top: 15px;
}

.settingImg.uploadWrp {
  width: 100%;
  position: relative;
  height: 80px;
  border-radius: 8px;
  border: 1px dashed #adadad;
}

.KYCformWrp.uploadFrmWrp .frmRow>div {
  width: 50%;

  @include respond-to(media-sm) {
    width: 100%;
  }
}

.settingImg.uploadWrp a.addIcon {
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  border: none;
  border-radius: 8px;
  cursor: unset;

  .file {
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}

.kyBox {
  margin-bottom: 10px;
}

.questAns .frmGrp {
  margin-bottom: 15px;
}

.dbCon {
  padding-bottom: 130px;
}

.chatBot_btn {
  background: transparent;
  outline: none;
  border: none;
  width: 80px;
  height: 80px;
}

.chatBotWrp {
  display: inline-block;
  position: fixed;
  z-index: 99;
  right: 28px;
  bottom: 28px;
}

.chatBWin {
  width: 344px;
  height: 466px;
  border-radius: 14px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
}

.chatBWin {
  position: absolute;
  bottom: 120%;
  right: 0;
  display: none;
  // height: 0;
  // overflow: hidden;
  transition: all 0.3s linear;
}

.chatBWin.open {
  display: block;
  // height: 466px;
  // overflow: visible;
}

.chBtHdr,
.chBtFtr,
.chBtBody {
  padding-left: 20px;
  padding-right: 20px;
}

.chBtHdr {
  border-radius: 13px 13px 0px 0px;
  background: #1c2d5c;
  height: 46px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  .usrName {
    font-size: 18px;
    color: #fff;
    text-transform: capitalize;
    margin: 0;
  }

  .clsBtn {
    font-size: 14px;
    color: #fff;
    border: none;
    outline: none;
    background: transparent;
  }
}

.chBtFtr {
  position: absolute;
  bottom: 20px;
  width: 100%;
  background: #fff;

  form {
    width: 100%;
  }

  .frmInner {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 8px;
    padding: 0 15px;
  }

  .frm-ctrl {
    width: calc(100% - 20px);
    height: 40px;
    font-size: 15px;
    line-height: 16px;
    padding: 12px 15px 12px 0;
    color: #8a8a8d;
    border-radius: 8px;
    background: #ffffff;
    border: none;
    outline: none !important;
    box-shadow: none;
  }

  .sentBtn {
    background: transparent;
    border: none;
    outline: transparent;
  }
}

.leftChat {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: nowrap;
  margin-bottom: 15px;

  .imgWrp {
    width: 34px;
    height: 34px;
    position: relative;
    overflow: hidden;
    margin-right: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .msgSec {
    width: calc(100% - 39px);

    .msgBox {
      border-radius: 15px;
      background: #f0f0f0;
      padding: 20px;

      .msgTxt {
        font-size: 14px;
        font-weight: normal;
        line-height: 18px;
        margin-bottom: 0;
      }

      .msgBtn {
        font-size: 13px;
        display: block;
        width: 100%;
        padding: 6px;
        text-align: center;
        letter-spacing: 0em;
        color: #1e2f60;
        border-radius: 8px;
        background: #ffffff;
        margin-top: 8px;
        cursor: pointer;

        &:hover {
          background: #1e2f60;
          color: #fff;
          box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
        }
      }
    }

    .msgTime {
      margin: 0;
      margin-top: 5px;
    }
  }
}

.chBtBody {
  height: calc(100% - 115px);
  padding-top: 15px;
  overflow: hidden;
  padding-bottom: 60px;
  overflow-y: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.rightChat {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: nowrap;
  margin-bottom: 18px;

  .msgBox {
    border-radius: 25px;
    background: #1d2e5e;
    padding: 15px;

    .msgTxt {
      font-size: 14px;
      line-height: 18px;
      color: #fff;
      margin: 0;
    }
  }

  .msgTime {
    margin: 0;
    margin-top: 5px;
    text-align: right;
  }
}

.msgTime {
  font-size: 11px;
  color: #1e2f60;
}

.dbLeft.unverified ul.dbMenu.top>li {
  opacity: 0.4;
  cursor: not-allowed;
}

.dbLeft.unverified ul.dbMenu.top>li:first-child+li {
  opacity: 1;
  cursor: pointer;
}

.dbLeft.unverified ul.dbMenu.bot>li {
  opacity: 0.4;
  cursor: not-allowed;
}

.dbLeft.unverified ul.dbMenu.bot>li:last-child {
  opacity: 1;
  cursor: pointer;
}

.newKeyBox.walletBox {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;

  .wll_left {
    display: flex;
    display: -webkit-flex;
    align-items: center;
  }

  .wllIcon {
    margin-right: 20px;
    position: relative;
    overflow: hidden;
    width: 102px;
    height: 102px;
  }

  .sbTTL {
    font-size: 16px;
    margin-bottom: 10px;
    color: #fff;
  }

  .wal_amt {
    font-size: 32px;
    margin-bottom: 0px;
    color: #fff;
  }
}

.BTN_wrp .bt {
  width: 200px;
  height: 54px;
  line-height: 54px;
  border-radius: 8px;
  opacity: 1;
  background: #d9f7e7;
  font-size: 18px;
  text-transform: capitalize;
  text-align: center;
  margin-right: 20px;
  cursor: poiner;
  outline: none;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.35);
  }
}

.wallDetailWrp {
  .sbTtl {
    font-size: 18px;
    margin: 0;
    text-transform: capitalize;
  }

  ul.bankDet {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      position: relative;
      padding: 20px 5px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: space-between;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background: #dcdcdc;
        bottom: 0;
        left: 0;
      }

      &:last-child:after {
        height: 0;
      }

      .bdLeft {
        display: flex;
        display: -webkit-flex;
        align-items: center;

        button {
          font-size: 16px;
          cursor: pointer;
          background: transparent;
          outline: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .addNewBtn {
        font-size: 16px;
        color: #1a73e9;
        background: transparent;

        &:hover {
          text-decoration: underline;
        }

        img {
          margin-right: 8px;
        }
      }

      .bnkLogo {
        width: 30px;
      }
    }
  }
}

/* The container */
.chkCus {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.chkCus input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.chkCus .checkmark {
  position: absolute;
  top: -10px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #c5c5c5;
}

/* On mouse-over, add a grey background color */
.chkCus:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.chkCus input:checked~.checkmark {
  background-color: #29d1b8;
}

/* Create the checkmark/indicator (hidden when not checked) */
.chkCus .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chkCus input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.chkCus .checkmark:after {
  left: 7px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bank_card,
.crdNumb {
  img {
    width: 30px;
  }
}

.crdNumb {
  img {
    margin-left: 12px;
    vertical-align: middle;
  }
}

.cardDesc {
  padding-left: 10px;

  .crdNumb {
    font-size: 16px;
    margin-bottom: 3px;
  }

  .bnk {
    font-size: 13px;
    color: #494949;
    margin: 0;
    text-transform: capitalize;
  }
}

.loginBtn.block.cancel {
  border: 1px solid #a7a7a7;
  background: #fff;
  color: #a7a7a7;

  &:hover {
    background: #000;
    color: #fff;
    border-color: transparent;
  }
}

.popBtnsWrp {
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .loginBtn {
      margin-top: 0;
    }
  }
}

.otpSec {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;

  input {
    width: 60px;
    height: 60px;
    text-align: center;
    padding: 10px;
    background: #dafbf6;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.dint {
  margin: 20px 0 0 0;
  font-size: 14px;
  color: #494949;
  text-align: center;

  .link {
    font-size: 14px;
    color: #494949;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }
}

.tac.loginText.otp {
  text-align: center;
  max-width: 278px;
  margin: 0 auto 20px;
}

.bankDetPopBox {
  max-width: 340px;
  margin: 0 auto;
}

.blBox {
  background: linear-gradient(90deg, #1d2f60 0%, #3c61c6 100%);
  height: 80px;
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  margin-bottom: 16px;

  p {
    margin-left: 12px;
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
  }
}

.bkDetCont {
  border-radius: 12px;
  border: 1px solid #dedede;
  padding: 20px 16px;
  margin-bottom: 20px;

  p {
    font-size: 18px;
    margin-bottom: 15px;
  }

  table {
    tr {
      td {
        font-size: 14px;
        padding-bottom: 17px;

        &:first-child {
          width: 108px;
        }

        &:last-child {
          padding-left: 45px;
        }
      }

      &:last-child {
        td {
          padding-bottom: 0;
        }
      }
    }
  }
}

.accToggInner {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;

  .p1 {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .p2 {
    font-size: 13px;
    color: #494949;
    margin: 0;
  }
}

.toggBtn {
  form {
    display: inline-block;
  }

  .form-check {
    margin: 0;
  }

  .form-switch {
    .form-check-input {
      width: 55px;
      margin: 0;
      height: 28px;

      &:checked {
        background-color: #44b678;
        border-color: #44b678;
      }
    }
  }
}

.btnDelWrp {
  margin-top: 45px;

  .delBank {
    font-size: 18px;
    text-align: center;
    color: #F95959;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #f95959;
    text-align: center;
    text-transform: capitalize;
    display: block;
    width: 100%;
    height: 56px;

    &:hover {
      background: #F95959;
      color: #fff;
    }
  }
}

.newKeyBox.walletBox {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;

  .wll_left {
    display: flex;
    display: -webkit-flex;
    align-items: center;
  }

  .wllIcon {
    margin-right: 20px;
    position: relative;
    overflow: hidden;
    width: 102px;
    height: 102px;
  }

  .sbTTL {
    font-size: 16px;
    margin-bottom: 10px;
    color: #fff;
  }

  .wal_amt {
    font-size: 32px;
    margin-bottom: 0px;
    color: #fff;
  }
}

.BTN_wrp .bt {
  width: 200px;
  height: 54px;
  line-height: 54px;
  border-radius: 8px;
  opacity: 1;
  background: #d9f7e7;
  font-size: 18px;
  text-transform: capitalize;
  text-align: center;
  margin-right: 20px;
  cursor: poiner;
  outline: none;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.35);
  }
}

.wallDetailWrp {
  .sbTtl {
    font-size: 18px;
    margin: 0;
    text-transform: capitalize;
  }

  ul.bankDet {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      position: relative;
      padding: 20px 5px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: space-between;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background: #dcdcdc;
        bottom: 0;
        left: 0;
      }

      &:last-child:after {
        height: 0;
      }

      .bdLeft {
        display: flex;
        display: -webkit-flex;
        align-items: center;

        button {
          font-size: 16px;
          cursor: pointer;
          background: transparent;
          outline: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .addNewBtn {
        font-size: 16px;
        color: #1a73e9;
        background: transparent;

        &:hover {
          text-decoration: underline;
        }

        img {
          margin-right: 8px;
        }
      }

      .bnkLogo {
        width: 30px;
      }
    }
  }
}

/* The container */
.chkCus {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.chkCus input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.chkCus .checkmark {
  position: absolute;
  top: -10px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #c5c5c5;
}

/* On mouse-over, add a grey background color */
.chkCus:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.chkCus input:checked~.checkmark {
  background-color: #29d1b8;
}

/* Create the checkmark/indicator (hidden when not checked) */
.chkCus .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chkCus input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.chkCus .checkmark:after {
  left: 7px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bank_card,
.crdNumb {
  img {
    width: 30px;
  }
}

.crdNumb {
  img {
    margin-left: 12px;
    vertical-align: middle;
  }
}

.cardDesc {
  padding-left: 10px;

  .crdNumb {
    font-size: 16px;
    margin-bottom: 3px;
  }

  .bnk {
    font-size: 13px;
    color: #494949;
    margin: 0;
    text-transform: capitalize;
  }
}

.loginBtn.block.cancel {
  border: 1px solid #a7a7a7;
  background: #fff;
  color: #a7a7a7;

  &:hover {
    background: #000;
    color: #fff;
    border-color: transparent;
  }
}

.popBtnsWrp {
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .loginBtn {
      margin-top: 0;
    }
  }
}

.otpSec {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;

  input {
    width: 60px;
    height: 60px;
    text-align: center;
    padding: 10px;
    background: #dafbf6;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.dint {
  margin: 20px 0 0 0;
  font-size: 14px;
  color: #494949;
  text-align: center;

  .link {
    font-size: 14px;
    color: #494949;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }
}

.tac.loginText.otp {
  text-align: center;
  max-width: 278px;
  margin: 0 auto 20px;
}

.bankDetPopBox {
  max-width: 340px;
  margin: 0 auto;
}

.blBox {
  background: linear-gradient(90deg, #1d2f60 0%, #3c61c6 100%);
  height: 80px;
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  margin-bottom: 16px;

  p {
    margin-left: 12px;
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
  }
}

.bkDetCont {
  border-radius: 12px;
  border: 1px solid #dedede;
  padding: 20px 16px;
  margin-bottom: 20px;

  p {
    font-size: 18px;
    margin-bottom: 15px;
  }

  table {
    tr {
      td {
        font-size: 14px;
        padding-bottom: 17px;

        &:first-child {
          width: 108px;
        }

        &:last-child {
          padding-left: 45px;
        }
      }

      &:last-child {
        td {
          padding-bottom: 0;
        }
      }
    }
  }
}

.accToggInner {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;

  .p1 {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .p2 {
    font-size: 13px;
    color: #494949;
    margin: 0;
  }
}

.toggBtn {
  form {
    display: inline-block;
  }

  .form-check {
    margin: 0;
  }

  .form-switch {
    .form-check-input {
      width: 55px;
      margin: 0;
      height: 28px;

      &:checked {
        background-color: #44b678;
        border-color: #44b678;
      }
    }
  }
}

.btnDelWrp {
  margin-top: 45px;

  .delBank {
    font-size: 18px;
    text-align: center;
    color: #F95959;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #f95959;
    text-align: center;
    text-transform: capitalize;
    display: block;
    width: 100%;
    height: 56px;

    &:hover {
      background: #F95959;
      color: #fff;
    }
  }
}

button.sbtn {
  background: red !important;
}

.no-p {
  padding: 0 !important;
}

button.viewLink {
  display: inline-flex;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: 1px solid #cfcfcf;
  height: 28px;
  padding: 0 15px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 6px;
}

button.viewLink:hover {
  background: #00b69b;
  border-color: #00b69b;
  color: #fff;
}


button.viewLink.unblock {
  color: #fd5454;
  border-color: #fd5454;
  background: #fdeaea;
}

button.viewLink.unblock:hover {
  background: #fd5454;
  color: #fff;
}